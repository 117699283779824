/* CUSTOM STYLES TO OVERWRITE THE DEFAULT EXPLORER STUFF */

.docExplorerWrap {
    height: 100vh;
    overflow-y: auto;
}

.doc-explorer-title-bar {
    display: flex;
    justify-content: space-between;
    padding: 0.5rem;
}

.graphiql-explorer-root {
    font-family: Roboto Mono;
}

.graphiql-container .toolbar {
    width: 100%;
}

.topBar .title {
    display: none;
}

.graphiql-container .topBar .execute-button-wrap {
    margin-left: 14px;
}
